import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import { Parallax } from 'react-scroll-parallax';
const Kontaktlencsek = () => (
  <Layout>
    <SEO title="Kontaktlencsék" description="Egyénre szabott optika Zugló szívében"  />
    <div className="sitewrap xl:max-w-2xl justify-center  mr-auto ml-auto      mt-10 ">
    <div className="  flex    mr-auto ml-auto   mt-20">
    <div className="F12F7B p-4      mb-10 mt-10 ">
    



<div className="left2 mt-20 "><h3 className="maintext2 left2 ">Megkönnyítik a mindennapokat, vagy különlegessé teheti a nagy napot (pl: esküvő).</h3>
<h3 className="maintext2 mt-2 left2">
A Cooper Vision cég lencséit illesztjük.</h3>

<h3 className="maintext2 mt-2 left2">
A lencse, ápolószer választásban is segítünk legyen akár:</h3>

<ul className="maintext2 mt-2 left2 text-left"> 
<li>napi</li>
<li>havi hordású</li>
<li>digitális eszközökhöz használható</li>
<li>multifokális</li>
</ul>
<h3 className="maintext2 font-bold mt-10  text-left text-justify"> 
Ápolószer és műkönny is kapható. </h3> 








</div></div>

    </div></div>
  </Layout>
)

export default Kontaktlencsek
